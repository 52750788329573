import React from "react"
import Layout from "../components/layout"
import Links from "../components/links"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <div className="contact">
      <SEO title="Contact | Yojan Regmi" />

      <h1>Get In touch</h1>
      <p>
        If you wanna get in touch, talk to me about a project collaboration or
        just say hi, shoot me an email at <strong>devyojan@gmail.com</strong>.
      </p>
      <h4>Let's Get Social</h4>
      <Links />
      </div>
    </Layout>
  )
}

export default Contact
