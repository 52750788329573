import React from "react"
import { FaFacebook, FaTwitter, FaGithub } from "react-icons/fa"

const Links = () => {
  return (
    <>
      <ul className="contactIcons">
        <li>
          <a href="https://www.facebook.com/yojan.regmi" target="_new">
            <FaFacebook />
          </a>
        </li>
        <li>
          <a href="https://github.com/DevYojan" target="_new">
            <FaGithub />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/devyojan" target="_new">
            <FaTwitter />
          </a>
        </li>
      </ul>
    </>
  )
}

export default Links
