import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"
import Menu from "./menu"
import Links from "./links"

const SideNav = ({ siteTitle }) => {
  return (
    <div className="sidenav">
      <div
        className="image"
        style={{
          maxWidth: `250px`,
          marginBottom: `1.45rem`,
          border: "1px solid #e1e4e8",
        }}
      >
        <Image />
      </div>
      <Link to="/">
        <h1>Yojan Regmi</h1>
      </Link>
      <p className="description">Web Developer | Learner</p>
      <Menu />
      <Links />
      <footer>© {new Date().getFullYear()}, Yojan Regmi</footer>
    </div>
  )
}

SideNav.propTypes = {
  siteTitle: PropTypes.string,
}

SideNav.defaultProps = {
  siteTitle: ``,
}

export default SideNav
