import React from "react"
import Link from "gatsby-link"

const activeStyle = {
  color: "#316aff",
  textDecoration: "underline",
}

const Menu = () => {
  return (
    <ul>
      <li>
        <Link to="/" activeStyle={activeStyle}>
          About
        </Link>
      </li>
      <li>
        <Link to="/projects/" activeStyle={activeStyle}>
          Projects
        </Link>
      </li>
      <li>
        <Link to="/contact/" activeStyle={activeStyle}>
          Contact
        </Link>
      </li>
      <li>
        <Link to="/blog/" activeStyle={activeStyle}>
          Blog
        </Link>
      </li>
    </ul>
  )
}

export default Menu
